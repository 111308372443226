export const TRANSLATIONS_FR = {
  contact: {
    message: `Pour toute demande de contact, merci de nous envoyer un email à l'adresse suivante : ${process.env.REACT_APP_ASSOCIATION_EMAIL}`,
  },
  login: {
    login: 'Connexion',
    password: 'Mot de passe',
    username: 'Email',
  },
  main: {
    subtitle: 'Version de développement',
    title: 'Association Française Franz Liszt',
  },
  menu: {
    about: `L'association`,
    contact: 'Contact',
    home: 'Menu',
    news: 'Actualités',
    posts: 'Articles',
    subscribe: 'Adhérer',
  },
  service: {
    building: 'Site en construction',
  },
}
